.login-form {
    width: 100%;
}

.login-form-button {
    width: 100%;
}

.consult {
    text-align: center;
    font-family: 'Garamond', Courier, monospace;
    font-size: 20px;
    height: 50px;
    width: 100%;
    background-color: #52ab98;
    padding: 10px;
    font-weight: 600;
    color: whitesmoke;
    margin-bottom: 40px;
}

.title {
    font-family: 'Garamond', serif;
    color: #2b6777;
    text-align: center;
    font-size: 32px;
}
