#services-h2 {
    font-family: 'Garamond', serif;
    color: #2b6777;
    text-align: center;
    font-size: 32px;
}

.service-about {
    font-size: 17px;
    font-family: 'Hind', sans-serif;
    color: #4a4541;
}

.list {
    text-align: left;
}

#power-of-attorney {
    color: red;
}

#estate-title {
    font-size: 17px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    line-height: 1.5715;
}