#about-h2 {
    font-family: 'Garamond', serif;
    color: #2b6777;
    text-align: center;
    font-size: 32px;
}

.about-section {
    font-size: 17px;
    font-family: 'Hind', sans-serif;
}