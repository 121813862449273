.paper {
	background: #2b6777;
	position: absolute;
	left: 11px !important;
	bottom: 50vh;
	font-family: 'Garamond', 'Nanum Myeongjo', serif;
	letter-spacing: normal !important;
	font-weight: 100 !important;
	color: whitesmoke !important;
	text-transform: none;
	padding: 10px 20px !important;
	font-size: 28px !important;
	margin-bottom: -50px !important;
	line-height: 1.3em !important;
	animation: fadeIn 6s;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}