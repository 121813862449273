.column {
    margin: 10px;
    margin-bottom: 10px;
}

h2 {
    font-family: 'Garamond', serif;
    color: #2b6777;
    text-align: center;
    font-size: 32px;
}

.team-about {
    font-family: 'Hind', sans-serif;
    font-size: 17px;
}
