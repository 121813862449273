.home-image-container {
	background: url('./home.jpg') center center/cover no-repeat;
	height: 70vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	object-fit: contain;
}

.home-paper {
	background: #2b6777;
	position: absolute;
	left: 15px !important;
	bottom: 30vh;
	font-family: 'Garamond', 'Nanum Myeongjo', serif;
	letter-spacing: normal !important;
	font-weight: 100 !important;
	color: whitesmoke !important;
	text-transform: none;
	padding: 10px 20px !important;
	font-size: 28px !important;
	margin-bottom: -50px !important;
	line-height: 1.3em !important;
	animation: fadeIn 6s;
}

@keyframes fadeIn {
	0% { opacity: 0; }
	100% { opacity: 1; }
}