.about-image-container {
	background: url('./about.jpg') center center/cover no-repeat;
	height: 50vh;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
	object-fit: contain;
}
