.space {
	background-color: #234d58;
	height: 50px;
}

#contact {
	font-size: 22px;
	color: rgb(56, 55, 55);
	font-weight: 500;
}

#slogan {
	font-size: 28px;
}